<template>
  <v-row>
    <v-dialog
      v-on="on"
      id="reset-modal-5344c54e"
      v-model="dialog"
      overlay-opacity="0.95"
      max-width="570px"
    >
      <template>
        <Button
          id="open-reset-modal-button-62aec016"
          size="small"
          type="float"
          texting="p2black grey3"
          :handleClick="open"
        ></Button>
      </template>
      <v-card>
        <v-container style="padding-bottom: 50px">
          <v-row justify="end">
            <v-col cols="auto" style="padding-right: 24px; padding-bottom: 0">
              <Images
                id="reset-close-icon-7693619a"
                name="close.svg"
                class="pointer"
                v-on:click.native="dialog = false"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto" style="padding-top: 9px">
              <Heading type="h3 grey2">Reset Password</Heading>
            </v-col>
          </v-row>
          <form
            action="#"
            id="reset-form-a5b591d2"
            @submit.prevent="onChange"
            autocomplete="off"
          >
            <v-row justify="center">
              <v-col
                cols="10"
                sm="8"
                style="padding-top: 40px; padding-bottom: 3px"
              >
                <v-text-field
                  autocomplete="off"
                  name="password"
                  v-model.trim="form.password"
                  :class="{
                    'error--text v-input--has-state':
                      ($v.form.password.$dirty && !$v.form.password.required) ||
                      ($v.form.password.$dirty &&
                        !$v.form.password.minLength) ||
                      ($v.form.password.$error &&
                        !$v.form.password.strength.valid &&
                        $v.form.password.required),
                  }"
                  id="password"
                  label="New Password"
                  :type="show1 ? 'text' : 'password'"
                  aria-required="true"
                  hide-details
                  outlined
                >
                  <template v-slot:append>
                    <v-icon @click="show1 = !show1" v-if="show1">{{
                      svgPathEye
                    }}</v-icon>
                    <v-icon @click="show1 = !show1" v-else>{{
                      svgPathEyeOff
                    }}</v-icon>
                  </template>
                </v-text-field>
                <div class="mylabel">
                  <Texting
                    type="p1 Fivethly"
                    v-if="$v.form.password.$dirty && !$v.form.password.required"
                    >Password is required</Texting
                  >
                  <Texting
                    type="p1 Fivethly"
                    v-if="
                      $v.form.password.$dirty && !$v.form.password.minLength
                    "
                    >Password minimum length is 8 letters</Texting
                  >
                  <Texting
                    type="p1 Fivethly"
                    v-if="
                      $v.form.password.$error &&
                      !$v.form.password.strength.valid &&
                      $v.form.password.required
                    "
                  >
                    At least 1 uppercase, 1 lowercase, 1 digit
                  </Texting>
                </div>
              </v-col>
              <v-col cols="10" sm="8">
                <v-text-field
                  autocomplete="off"
                  name="confirmPassword"
                  id="confirmPassword"
                  v-model="form.confirmPassword"
                  :class="{
                    'error--text v-input--has-state':
                      $v.form.confirmPassword.$dirty &&
                      !$v.form.confirmPassword.sameAsPassword,
                  }"
                  label="Confirm Password"
                  :type="show2 ? 'text' : 'password'"
                  aria-required="true"
                  hide-details
                  outlined
                >
                  <template v-slot:append>
                    <v-icon @click="show2 = !show2" v-if="show2">{{
                      svgPathEye
                    }}</v-icon>
                    <v-icon @click="show2 = !show2" v-else>{{
                      svgPathEyeOff
                    }}</v-icon>
                  </template>
                </v-text-field>
                <div class="mylabel">
                  <Texting
                    type="p1 Fivethly"
                    v-if="
                      $v.form.confirmPassword.$dirty &&
                      !$v.form.confirmPassword.sameAsPassword
                    "
                    >Passwords do not match</Texting
                  >
                </div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10" sm="8" style="padding-top: 18px">
                <Button
                  id="reset-button-b944c63c"
                  b_type="submit"
                  size="block"
                  texting="p3bold mywhite"
                  style="
                    padding-top: 16px !important;
                    padding-bottom: 16px !important;
                  "
                  :handleClick="function () {}"
                  type="primary"
                  >reset</Button
                >
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card>
    </v-dialog>
    <Confirmation
      text1="Success!"
      text2="Your password has been changed."
      ref="modal"
    />
  </v-row>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { strength } from "../utils/passStrength.js";

import axios from "axios";
import myServices from "../services/resource";
import Button from "./Button.vue";
import Texting from "./Texting";
import Heading from "./Heading";
import Confirmation from "./Confirmation";
import Images from "./MyImages";
import router from "../plugins/router";
import { mdiEye } from "@mdi/js";
import { mdiEyeOff } from "@mdi/js";

export default {
  components: {
    Images,
    Confirmation,
    Heading,
    Texting,
    Button,
  },
  props: {
    reset_password: Boolean,
  },
  data: () => ({
    svgPathEye: mdiEye,
    svgPathEyeOff: mdiEyeOff,
    dialog: true,
    show1: false,
    show2: false,
    form: {
      password: "",
      confirmPassword: "",
    },
  }),
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        strength,
      },
      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  mounted() {
    this.dialog = this.reset_password;
  },
  methods: {
    onChange() {
      if (this.$v.$invalid) {
        this.$v.form.$touch();
        this.dialog = true;
        return;
      }
      axios
        .post(`/api/auth/reset/${this.$route.query.token}`, this.form, {
          withCredentials: true,
        })
        .then(() => {
          this.close();
          router.push({
            name: "user",
          });
        })
        .catch((error) => {
          this.open();
          myServices.showErrorMessage(this, error);
          /*let message = "Oops, something went wrong";
                    if (error.response && error.response.data) {
                    	message = error.response.data.message;
                    }
                    this.$toasted.error(message, {
                    	duration: 5000,
                    	className: "toast-error",
                    });*/
        });
    },
    open: function () {
      return (this.dialog = true);
    },
    close: function () {
      return (this.dialog = false);
    },
    openConfirm: function () {
      this.close();
      //this.$refs.modal.show();
    },
  },
};
</script>
