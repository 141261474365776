<template>
  <div id="homepage_container" style="background-color: white">
    <AppHeader />
    <StartPage @opensignup="openSignUp()" />
    <v-container
      class="ma-0 pa-0"
      style="background-color: #ffffff; width: 100%; overflow: hidden"
      fluid
    >
      <HomeVideoSection />
      <TopUpPage />
      <HomeCards />
      <SetupPage />
      <BackersSee />
      <ExplanationPage />
      <CustomersSay style="position: relative; z-index: 5" />
      <KSDailyHomePage />
      <FreeTrialPage @open-signup="openSignUp()" />
    </v-container>
    <Reset
      v-if="$route.query.reset === 'true'"
      :reset_password="$route.query.reset === 'true'"
    />
    <LoginAndSignUp
      @closed="active = false"
      :value="value"
      :active="openModal()"
      @onsignup-click="value = 'signup'"
      @onlogin-click="value = 'login'"
    />
    <AppFooter />
  </div>
</template>

<script>
import Reset from "../../components/Reset";
import ExplanationPage from "./ExplanationPage";
import StartPage from "./StartPage";
import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/AppFooter";
import FreeTrialPage from "../../components/FreeTrialPage";
import LoginAndSignUp from "../../components/LoginAndSignUp";
import TopUpPage from "./TopUpPage";
import SetupPage from "@/views/HomePage/SetupPage";
import HomeCards from "@/views/HomePage/HomeCards";
import BackersSee from "./BackersSee";
import CustomersSay from "./CustomersSay";
import KSDailyHomePage from "@/views/HomePage/KSDailyHomePage";
import router from "@/plugins/router";
import HomeVideoSection from "@/views/HomePage/HomeVideoSection";

// TODO: follow vue official style guide for order of properties https://vuejs.org/v2/style-guide/
export default {
  components: {
    HomeVideoSection,
    KSDailyHomePage,
    CustomersSay,
    BackersSee,
    HomeCards,
    SetupPage,
    LoginAndSignUp,
    FreeTrialPage,
    ExplanationPage,
    StartPage,
    AppHeader,
    Reset,
    AppFooter,
    TopUpPage,
  },
  data: () => ({
    value: "",
    active: false,
    loaded: false,
  }),
  async mounted() {
    if (this.$route.query.activate) {
      await router.push({ path: "/", query: { login: "true" } });
    }
    if (this.$route.query.login === "true") {
      this.value = "login";
      this.active = true;
    }
    this.loaded = true;
    this.$meta().refresh;
    let event = new Event("renderer-ready");
    window.document.dispatchEvent(event);
  },
  metaInfo: {
    title: "Perkfection: Automate Your Kickstarter & Indiegogo Rewards",
    meta: [
      {
        name: "description",
        content:
          "Increase your Kickstarter & Indiegogo conversions by 23%. Perkfection automates your rewards with 30-second top-ups, so there’s always only a few left!",
      },
      {
        property: "og:description",
        content:
          "Increase your Kickstarter & Indiegogo conversions by 23%. Perkfection automates your rewards with 30-second top-ups, so there’s always only a few left!",
      },
      {
        property: "og:title",
        content: "Perkfection: Automate Your Kickstarter & Indiegogo Rewards",
      },
      {
        property: "og:site_name",
        content: "Perkfection",
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:url",
        content: "https://perkfection.com",
      },
      {
        property: "og:image",
        content: "https://storage.googleapis.com/btest6.ao.am/facebook.png",
      },
      {
        name: "keywords",
        content:
          "Kickstarter rewards, Indiegogo perks, Kickstarter promotion, how to win at Kickstarter, kickstarter marketing, best way to promote your kickstarter project, how to promote kickstarter, how to promote your kickstarter, promote kickstarter, promote kickstarter campaign, kickstarter promotion services",
      },
    ],
  },
  methods: {
    openSignUp() {
      this.value = "signup";
      this.active = true;
    },
    openModal() {
      return this.active;
    },
  },
};
</script>

<style scoped>
hr {
  width: 100%;
  opacity: 0.1;
  border: 1px solid #000000;
}
</style>
