<template>
  <v-row justify="center">
    <v-col
      cols="auto"
      class="text-center pt-12 pt-md-0 pb-0 pb-sm-3 px-6 px-sm-3"
    >
      <Heading type="h2 MainDark" class="shadow-text"
        >Get Your Backers to Act Now</Heading
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-col
      cols="12"
      class="pt-2 pt-sm-3 px-6 -x-sm-3"
      style="max-width: 724px; padding-bottom: 0"
    >
      <Texting type="p4 MainDark" style="text-align: center; opacity: 0.8"
        >Anyone visiting your Kickstarter or Indiegogo campaign page after you
        activate Perkfection will always see your rewards are nearly sold out…
        oh no! Act quick!</Texting
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-col
      cols="12"
      style="max-width: 1200px; position: relative; padding-top: 0"
    >
      <!-- <Images style="z-index: 2;position: relative" width="100%" name="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703033/images/backers_see_ve9pkn.png" /> -->
      <images-lazy
        style="z-index: 2; position: relative"
        width="100%"
        background-color="transparent"
        lazy-src="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703033/images/backers_see_ve9pkn.png"
      />
      <div
        class="d-none d-lg-flex"
        style="position: absolute; bottom: 70px; float: right; right: 192px"
      >
        <Images name="table_vector1.png" />
      </div>
      <div
        class="d-none d-lg-flex"
        style="position: absolute; float: right; bottom: 26px; right: 32px"
      >
        <Images name="table_vector2.png" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Heading from "@/components/Heading";
import Images from "@/components/MyImages";
import Texting from "@/components/Texting";
import ImagesLazy from "../../components/ImagesLazy.vue";
export default {
  components: { Texting, Images, Heading, ImagesLazy },
};
</script>

<style scoped>
@media only screen and (max-width: 599px) {
  .h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
  }
  .p4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
}
</style>
