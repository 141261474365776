<template>
  <v-row justify="center">
    <v-col
      cols="12"
      class="pb-0 ksdaily-header"
      style="padding-top: 150px; max-width: 455px; position: relative"
    >
      <Images
        name="free_feature.svg"
        class="free-feature-icon"
        style="position: absolute; bottom: 95px; left: 0"
      />
      <Heading type="h2 MainDark" class="shadow-text text-center px-4"
        >Kickstarter Daily Analytics Dashboard</Heading
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-col cols="12" class="pt-2 pt-sm-3" style="max-width: 724px">
      <Texting type="p4 MainDark" style="text-align: center; opacity: 0.8"
        >Track Your Kickstarter Campaign Referrers' Conversions 24/7 inside your
        Perkfection account!</Texting
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-row justify="center">
      <v-col
        cols="auto"
        class="px-6 ksdaily-grid-container"
        style="padding-bottom: 76px"
      >
        <v-row justify="center">
          <v-col
            cols="12"
            sm="4"
            class="pb-o pt-3 pt-sm-12"
            style="max-width: 357px"
          >
            <Images
              class="mx-auto d-block mb-6"
              name="ks_daily_home_icon1.svg"
            />
            <Texting type="p3 MainDark" class="text-center" style="opacity: 0.8"
              >Gain control over your traffic sources and their
              conversions</Texting
            >
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="pb-o pt-3 pt-sm-10"
            style="max-width: 357px"
          >
            <Images
              class="mx-auto d-block mb-4"
              name="ks_daily_home_icon2.svg"
            />
            <Texting type="p3 MainDark" class="text-center" style="opacity: 0.8"
              >Effortlessly search your Kickstarter referral tags</Texting
            >
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="pb-0 pt-3 pt-sm-12"
            style="max-width: 357px"
          >
            <Images
              class="mx-auto d-block"
              style="margin-bottom: 30px"
              name="ks_daily_home_icon3.svg"
            />
            <Texting type="p3 MainDark" class="text-center" style="opacity: 0.8"
              >Sort and export your data anytime you need</Texting
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div style="width: 100%"></div>
    <v-row justify="center" justify-lg="start">
      <v-col lg="1" xl="3" class="d-none d-lg-flex pb-0"></v-col>
      <v-col
        cols="10"
        md="8"
        class="pa-0 ks-dashboard-table"
        style="max-width: 815px"
      >
        <!-- <Images width="100%" name="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703031/images/ks_dashboard_table_ks_yvylgf.png"/> -->
        <images-lazy
          width="100%"
          background-color="transparent"
          lazy-src="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703031/images/ks_dashboard_table_ks_yvylgf.png"
        />
      </v-col>
    </v-row>
    <div style="width: 100%"></div>
    <v-row
      justify="center"
      class="ksdaily-table-pf-row"
      justify-lg="end"
      style="padding-top: 54px; padding-bottom: 80px; position: relative"
    >
      <v-col
        cols="auto"
        class="pa-0 d-none d-lg-flex"
        style="position: relative"
      >
        <Images
          style="position: absolute; right: 20px; top: -28px"
          name="ks_daily_home_arrow.svg"
        />
      </v-col>
      <v-col
        cols="10"
        md="8"
        class="pa-0 ks-dashboard-table"
        style="max-width: 810px"
      >
        <!--<Images width="100%" name="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703031/images/ks_dashboard_table_pf_af7tp7.png"/> -->
        <images-lazy
          width="100%"
          background-color="transparent"
          lazy-src="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703031/images/ks_dashboard_table_pf_af7tp7.png"
        />
      </v-col>
      <v-col lg="1" xl="3" class="d-none d-lg-flex pb-0"></v-col>
    </v-row>
  </v-row>
</template>

<script>
import Heading from "@/components/Heading";
import Images from "@/components/MyImages";
import Texting from "@/components/Texting";
import ImagesLazy from "../../components/ImagesLazy.vue";
export default {
  components: { Texting, Images, Heading, ImagesLazy },
  data: () => ({}),
};
</script>

<style scoped>
.ks-dashboard-table {
  box-shadow: 0px 22.6763px 100.042px rgba(0, 0, 0, 0.07),
    0px 8.27721px 36.5171px rgba(0, 0, 0, 0.0482987),
    0px 4.01843px 17.7284px rgba(0, 0, 0, 0.0389404),
    0px 1.96991px 8.69078px rgba(0, 0, 0, 0.0310596),
    0px 0.778906px 3.43635px rgba(0, 0, 0, 0.0217013);
}
@media only screen and (max-width: 599px) {
  .free-feature-icon {
    position: relative !important;
    left: 1% !important;
    bottom: -20px !important;
  }
  .h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
  }
  .p4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
  }
  .ksdaily-header {
    padding-top: 50px !important;
  }
  .ksdaily-grid-container {
    padding-bottom: 20px !important;
  }
  .ksdaily-table-pf-row {
    padding-top: 20px !important;
    padding-bottom: 40px !important;
  }
}
</style>
