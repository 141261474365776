<template>
  <v-sheet min-height="470" class="fill-height" color="transparent">
    <v-lazy
      max-width="333"
      v-model="project"
      class="fill-height"
      :options="{ threshold: 0.9 }"
    >
      <v-card class="homecard" max-width="333" height="470px">
        <Images :name="project.image" />
        <div class="d-flex inform-panel justify-space-between">
          <div>
            <Texting type="label4 mywhite" style="padding-right: 2px"
              >Funding raised</Texting
            >
            <Texting type="p2bold mywhite" style="line-height: 17px">{{
              project.funded
            }}</Texting>
          </div>
          <div class="d-flex align-center">
            <Images :name="project.platform + '_card.png'" />
          </div>
        </div>
        <Texting
          type="p2bold grey1"
          style="opacity: 0.85; padding-bottom: 8px; padding-top: 17px"
          >{{ project.title }}
        </Texting>
        <Texting type="p1 best-dark" style="opacity: 0.6">{{
          project.description
        }}</Texting>
      </v-card>
    </v-lazy>
  </v-sheet>
</template>

<script>
import Images from "@/components/MyImages";
import Texting from "@/components/Texting";

export default {
  components: { Texting, Images },
  data: () => ({}),
  props: {
    project: Object,
  },
};
</script>

<style scoped>
.homecard {
  position: relative;
  display: inline-block;
  width: 333px;
  padding: 17px 17px 0 17px;
  background: #ffffff;
  /*box-shadow: 0 17px 75px rgba(0, 0, 0, 0.07), 0px 6.20528px 27.3762px rgba(0, 0, 0, 0.0482987), 0px 3.01255px 13.2907px rgba(0, 0, 0, 0.0389404), 0px 1.47681px 6.51533px rgba(0, 0, 0, 0.0310596), 0px 0.583932px 2.57617px rgba(0, 0, 0, 0.0217013);*/
  border-radius: 10px !important;
  /*margin: 40px 20px 60px 20px;*/
  z-index: 10;
}

.inform-panel {
  position: absolute;
  top: 269px;
  width: 100%;
  max-width: 299px;
  background: #15202b;
  opacity: 0.9;
  border-radius: 0 0 3.12187px 3.12187px;
  padding: 6px 8px 8px 8px;
}
</style>
