<template>
  <div>
    <v-row justify="center" style="background-color: #ffffff">
      <v-col
        cols="10"
        style="max-width: 1200px; padding-bottom: 0"
        class="pt-o pt-sm-12"
      >
        <v-row justify="center" align="center" class="mb-12">
          <v-col class="d-md-flex d-none pr-md-10" cols="auto" md="5">
            <video
              class="lazy"
              width="100%"
              height="100%"
              title="perks top up automation"
              poster="https://res.cloudinary.com/dyf7aejih/image/upload/v1622209573/images/video_poster1_yhmil0.jpg"
              autoplay
              loop
              muted
              playsinline
              preload
            >
              <source
                data-src="https://storage.googleapis.com/btest6.ao.am/Full_1.webm"
                type="video/webm"
              />
              <source
                data-src="https://storage.googleapis.com/btest6.ao.am/Full_1.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </v-col>
          <v-col cols="auto" md="6" class="exp1 pb-0 pb-sm-12">
            <div>
              <Heading class="shadow-text pb-2 pb-sm-5" type="h2 MainDark"
                >Never Run Out<br class="d-none d-sm-flex" />
                of Rewards</Heading
              >
              <Texting type="p4 MainDark" style="opacity: 0.8; max-width: 430px"
                >With every minute of “No Rewards Left”, your Kickstarter or
                Indiegogo campaign is losing sales. Perkfection checks your
                reward levels every 30 seconds, and tops up if you’re running
                low.</Texting
              >
            </div>
          </v-col>
          <v-col class="d-md-none d-flex" cols="auto" md="5">
            <video
              class="lazy"
              width="100%"
              height="100%"
              title="perks top up automation"
              poster="https://res.cloudinary.com/dyf7aejih/image/upload/v1622209573/images/video_poster1_yhmil0.jpg"
              autoplay
              loop
              muted
              playsinline
              preload
            >
              <source
                data-src="https://storage.googleapis.com/btest6.ao.am/Full_1.webm"
                type="video/webm"
              />
              <source
                data-src="https://storage.googleapis.com/btest6.ao.am/Full_1.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="auto" md="6" class="exp2 pb-0 pb-sm-12">
            <div>
              <Heading class="shadow-text pb-2 pb-sm-5" type="h2 MainDark"
                >Increase Campaign<br class="d-none d-sm-flex" />
                Page Conversions</Heading
              >
              <Texting type="p4 MainDark" style="opacity: 0.8; max-width: 430px"
                >The less supply you have, the bigger the demand! By keeping
                your rewards on the edge of running out, you will see your
                conversion rate rise by up to 23%.</Texting
              >
            </div>
          </v-col>
          <v-col cols="auto" md="5" class="pl-md-10">
            <video
              class="lazy"
              width="100%"
              height="100%"
              title="last limited perks"
              poster="https://res.cloudinary.com/dyf7aejih/image/upload/v1622209573/images/video_poster2_iqsx3l.jpg"
              autoplay
              loop
              muted
              playsinline
              preload
            >
              <source
                data-src="https://storage.googleapis.com/btest6.ao.am/walk_cycle.webm"
                type="video/webm"
              />
              <source
                data-src="https://storage.googleapis.com/btest6.ao.am/walk_cycle.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="auto" md="5" class="pl-md-10 pb-0 d-none d-md-flex">
            <video
              class="lazy"
              width="100%"
              height="100%"
              poster="https://res.cloudinary.com/dyf7aejih/image/upload/v1622209573/images/video_poster3_xpljus.jpg"
              autoplay
              loop
              muted
              playsinline
              preload
            >
              <source
                data-src="https://storage.googleapis.com/btest6.ao.am/Asseeset.webm"
                type="video/webm"
              />
              <source
                data-src="https://storage.googleapis.com/btest6.ao.am/Asseeset.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </v-col>
          <v-col cols="auto" md="6" class="exp3 pb-0 pb-sm-12">
            <div>
              <Heading class="shadow-text pb-2 pb-sm-5" type="h2 MainDark"
                >Always in Control</Heading
              >
              <Texting type="p4 MainDark" style="opacity: 0.8; max-width: 430px"
                >Perkfection lets you know what’s happening with your
                Kickstarter promotion via email and SMS notifications.</Texting
              >
            </div>
          </v-col>
          <v-col cols="auto" md="5" class="pl-md-10 pb-0 d-flex d-md-none">
            <video
              class="lazy"
              width="100%"
              height="100%"
              poster="https://res.cloudinary.com/dyf7aejih/image/upload/v1622209573/images/video_poster3_xpljus.jpg"
              autoplay
              loop
              muted
              playsinline
              preload
            >
              <source
                data-src="https://storage.googleapis.com/btest6.ao.am/Asseeset.webm"
                type="video/webm"
              />
              <source
                data-src="https://storage.googleapis.com/btest6.ao.am/Asseeset.mp4"
                type="video/mp4"
              />
              <!--<source type="video/webm" src="../../assets/videos/Asseeset.webm">-->
              Your browser does not support the video tag.
            </video>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Heading from "../../components/Heading";
import Texting from "../../components/Texting";
export default {
  data: () => ({}),
  components: {
    Texting,
    Heading,
  },
  mounted() {
    document.addEventListener("load", this.init());
  },
  methods: {
    init: function () {
      var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
      if ("IntersectionObserver" in window) {
        var lazyVideoObserver = new IntersectionObserver(function (entries) {
          entries.forEach(function (video) {
            if (video.isIntersecting) {
              for (var source in video.target.children) {
                var videoSource = video.target.children[source];
                if (
                  typeof videoSource.tagName === "string" &&
                  videoSource.tagName === "SOURCE"
                ) {
                  videoSource.src = videoSource.dataset.src;
                }
              }
              video.target.load();
              video.target.classList.remove("lazy");
              lazyVideoObserver.unobserve(video.target);
            }
          });
        });
        lazyVideos.forEach(function (lazyVideo) {
          lazyVideoObserver.observe(lazyVideo);
        });
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .exp1 {
    max-width: 501px;
  }

  .exp2 {
    max-width: 537px;
  }

  .exp3 {
    max-width: 430px;
  }
}

@media only screen and (max-width: 599px) {
  .h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
  }

  .p4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
}
</style>
