var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-dialog',_vm._g({attrs:{"id":"reset-modal-5344c54e","overlay-opacity":"0.95","max-width":"570px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},_vm.on),[[_c('Button',{attrs:{"id":"open-reset-modal-button-62aec016","size":"small","type":"float","texting":"p2black grey3","handleClick":_vm.open}})],_c('v-card',[_c('v-container',{staticStyle:{"padding-bottom":"50px"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticStyle:{"padding-right":"24px","padding-bottom":"0"},attrs:{"cols":"auto"}},[_c('Images',{staticClass:"pointer",attrs:{"id":"reset-close-icon-7693619a","name":"close.svg"},nativeOn:{"click":function($event){_vm.dialog = false}}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"padding-top":"9px"},attrs:{"cols":"auto"}},[_c('Heading',{attrs:{"type":"h3 grey2"}},[_vm._v("Reset Password")])],1)],1),_c('form',{attrs:{"action":"#","id":"reset-form-a5b591d2","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onChange.apply(null, arguments)}}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"padding-top":"40px","padding-bottom":"3px"},attrs:{"cols":"10","sm":"8"}},[_c('v-text-field',{class:{
                  'error--text v-input--has-state':
                    (_vm.$v.form.password.$dirty && !_vm.$v.form.password.required) ||
                    (_vm.$v.form.password.$dirty &&
                      !_vm.$v.form.password.minLength) ||
                    (_vm.$v.form.password.$error &&
                      !_vm.$v.form.password.strength.valid &&
                      _vm.$v.form.password.required),
                },attrs:{"autocomplete":"off","name":"password","id":"password","label":"New Password","type":_vm.show1 ? 'text' : 'password',"aria-required":"true","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.show1)?_c('v-icon',{on:{"click":function($event){_vm.show1 = !_vm.show1}}},[_vm._v(_vm._s(_vm.svgPathEye))]):_c('v-icon',{on:{"click":function($event){_vm.show1 = !_vm.show1}}},[_vm._v(_vm._s(_vm.svgPathEyeOff))])]},proxy:true}]),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.password"}}),_c('div',{staticClass:"mylabel"},[(_vm.$v.form.password.$dirty && !_vm.$v.form.password.required)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Password is required")]):_vm._e(),(
                    _vm.$v.form.password.$dirty && !_vm.$v.form.password.minLength
                  )?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Password minimum length is 8 letters")]):_vm._e(),(
                    _vm.$v.form.password.$error &&
                    !_vm.$v.form.password.strength.valid &&
                    _vm.$v.form.password.required
                  )?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v(" At least 1 uppercase, 1 lowercase, 1 digit ")]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"10","sm":"8"}},[_c('v-text-field',{class:{
                  'error--text v-input--has-state':
                    _vm.$v.form.confirmPassword.$dirty &&
                    !_vm.$v.form.confirmPassword.sameAsPassword,
                },attrs:{"autocomplete":"off","name":"confirmPassword","id":"confirmPassword","label":"Confirm Password","type":_vm.show2 ? 'text' : 'password',"aria-required":"true","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.show2)?_c('v-icon',{on:{"click":function($event){_vm.show2 = !_vm.show2}}},[_vm._v(_vm._s(_vm.svgPathEye))]):_c('v-icon',{on:{"click":function($event){_vm.show2 = !_vm.show2}}},[_vm._v(_vm._s(_vm.svgPathEyeOff))])]},proxy:true}]),model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}}),_c('div',{staticClass:"mylabel"},[(
                    _vm.$v.form.confirmPassword.$dirty &&
                    !_vm.$v.form.confirmPassword.sameAsPassword
                  )?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Passwords do not match")]):_vm._e()],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"padding-top":"18px"},attrs:{"cols":"10","sm":"8"}},[_c('Button',{staticStyle:{"padding-top":"16px !important","padding-bottom":"16px !important"},attrs:{"id":"reset-button-b944c63c","b_type":"submit","size":"block","texting":"p3bold mywhite","handleClick":function () {},"type":"primary"}},[_vm._v("reset")])],1)],1)],1)],1)],1)],2),_c('Confirmation',{ref:"modal",attrs:{"text1":"Success!","text2":"Your password has been changed."}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }