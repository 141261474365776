<template>
  <v-row justify="center" style="position: relative">
    <v-col cols="auto" class="pb-sm-5 pb-0 pt-0 px-6">
      <Heading type="h2 MainDark" class="shadow-text" style="text-align: center"
        >Automatic Top Ups Every 30 Seconds</Heading
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-col
      cols="12"
      class="px-7"
      style="max-width: 1200px; padding-bottom: 0px"
    >
      <v-row justify="center">
        <v-col cols="auto" class="py-4 py-sm-7 px-6">
          <div class="grid-back">
            <div class="grid-custom">
              <Images name="effortless_icon.png" />
              <Texting type="p4-1 MainDark" style="padding-top: 10px"
                >Effortless</Texting
              >
              <Texting type="p2 MainDark" style="opacity: 0.8"
                >Reward tops up automatically on Kickstarter or
                Indiegogo</Texting
              >
            </div>
          </div>
        </v-col>
        <v-col cols="auto" class="py-4 py-sm-7 px-6">
          <div class="grid-back">
            <div class="grid-custom">
              <Images name="fast_icon.png" />
              <Texting type="p4-1 MainDark" style="padding-top: 10px"
                >Fast</Texting
              >
              <Texting type="p2 MainDark" style="opacity: 0.8"
                >Perkfection monitors your perks every 30 seconds</Texting
              >
            </div>
          </div>
        </v-col>
        <v-col cols="auto" class="py-4 py-sm-7 px-6">
          <div class="grid-back">
            <div class="grid-custom">
              <Images name="secure_icon.png" />
              <Texting type="p4-1 MainDark" style="padding-top: 10px"
                >Secure</Texting
              >
              <Texting type="p2 MainDark" style="opacity: 0.8"
                >Perkfection never accesses any of your data</Texting
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <Images name="leftd-sector.png" class="left-sector d-none d-sm-flex" />
  </v-row>
</template>

<script>
import Heading from "@/components/Heading";
import Images from "@/components/MyImages";
import Texting from "@/components/Texting";
export default {
  components: { Texting, Images, Heading },
  data: () => ({}),
};
</script>

<style scoped>
.grid-back {
  background: #eedaf9;
  border-radius: 12px;
  max-width: 325px;
  width: 100%;
}
.grid-custom {
  background: #ffffff;
  border: 1px solid #9e33e0;
  border-radius: 10px;
  position: relative;
  left: 8px;
  top: 8px;
  max-width: 325px;
  width: 100%;
  padding: 32px 47px 24px 32px;
}
@media only screen and (max-width: 599px) {
  .h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
  }
}
.left-sector {
  position: absolute;
  left: 12px;
  top: -74px;
}
</style>
