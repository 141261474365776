<template>
  <v-row justify="center">
    <v-col
      cols="auto"
      class="homecards-section-header pb-1 pb-sm-3"
      style="margin-top: 109px"
    >
      <Heading type="h2 MainDark" class="shadow-text" style="text-align: center"
        >Trusted by Successful<br />
        Campaigns</Heading
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-col
      cols="12"
      style="max-width: 724px"
      class="px-6 pb-sm-9 pb-1 text-center"
    >
      <Texting type="p4 MainDark" style="opacity: 0.8"
        >Perkfection has helped campaigns raise over $895 000 more by keeping
        their reward limits low.</Texting
      >
    </v-col>
    <div style="width: 100%"></div>
    <splide :options="myOptions" style="width: 100%">
      <template v-slot:controls>
        <div class="splide__arrows d-flex justify-center">
          <div class="splide__arrow--prev pr-4">
            <Images id="home-cards-prev-icon-cd3947b4" name="prev_icon.png" />
          </div>
          <div class="splide__arrow--next">
            <Images id="home-cards-next-icon-d9d453ec" name="next_icon.png" />
          </div>
        </div>
      </template>
      <splide-slide
        v-for="(project, i) in projects"
        :id="'home-card-' + i"
        :key="i"
        class="d-flex splide-card"
      >
        <HomeCard :project="project" />
      </splide-slide>
    </splide>
  </v-row>
</template>

<script>
import Heading from "@/components/Heading";
import HomeCard from "@/components/HomeCard";
import Images from "@/components/MyImages";
import Texting from "../../components/Texting";
export default {
  components: { Texting, Images, HomeCard, Heading },
  data: () => ({
    myOptions: {
      type: "loop",
      focus: "center",
      autoWidth: true,
      perMove: 1,
      pagination: false,
      arrows: "slider",
    },
    dist: 0,
    model: null,
    slideWidth: null,
    windowWidth: null,
    next: false,
    prev: false,
    projects: [
      {
        image:
          "https://res.cloudinary.com/dyf7aejih/image/upload/v1617703040/images/pico_card_sjyito.jpg",
        platform: "kickstarter",
        funded: "$1,695,553",
        title: "PICO: A garden in your palm. Growing is fun again!",
        description:
          "Grow thriving plants everywhere—with telescopic LED grow lights, multiple mounts, and a self-watering system. Anyone can do it!",
      },
      {
        image:
          "https://res.cloudinary.com/dyf7aejih/image/upload/v1617703036/images/origami_card_lcxizi.jpg",
        platform: "kickstarter",
        funded: "$3,835,587",
        title: "Origami Paddler: The World's Only Hard Shell Folding Stand-up",
        description:
          "A portable & versatile 9-foot Stand-Up Paddleboard and Kayak that doesn't require extra room to store and sets up in 1 minute!",
      },
      {
        image:
          "https://res.cloudinary.com/dyf7aejih/image/upload/v1617703052/images/uvmask_card_g8lo12.jpg",
        platform: "indiegogo",
        funded: "$3,177,336",
        title: "UVMask: All-Day, Lab-Certified, UV-C Sterilization",
        description:
          "The next-generation reusable, ozone-free face mask that filters pollutants, dust, smoke, droplets, pollen & leaf mulch.",
      },
      {
        image:
          "https://res.cloudinary.com/dyf7aejih/image/upload/v1617703029/images/kittyspring_card_wsurzj.jpg",
        platform: "kickstarter",
        funded: "$718,901",
        title: "KittySpring: The most cat-friendly water fountain on Earth",
        description:
          "Keep your picky drinker always hydrated with non-electric & whisker-friendly water fountain without having to refresh their bowls.",
      },
      {
        image:
          "https://res.cloudinary.com/dyf7aejih/image/upload/v1617703036/images/bru_card_uwjypi.jpg",
        platform: "indiegogo",
        funded: "$1,302,240",
        title: "BRU: The Ultimate Tea Machine",
        description:
          "BRÜ makes the perfect cup of tea at the push of a button. Use ANY tea. Set temperature, cup size & brew time. Saving you time & energy.x`",
      },
      {
        image:
          "https://res.cloudinary.com/dyf7aejih/image/upload/v1617703043/images/ciga_card_xtlif8.jpg",
        platform: "indiegogo",
        funded: "$2,294,889",
        title: "CIGA Design Z-Series Mechanical Titanium Watch",
        description:
          "Designed to highlight real men’s love of complexity with outstanding design and superb performance.",
      },
      {
        image:
          "https://res.cloudinary.com/dyf7aejih/image/upload/v1617703018/images/himo_card_p7ecgz.jpg",
        platform: "indiegogo",
        funded: "$631,021",
        title: "HIMO Z20: The Ultra-Dynamic Dual Mode E-Bike",
        description:
          "The E-Bike with everything you need for Lightweight, Energy-Efficient Travel. It’s time to ride in style with a bike that has it all.",
      },
    ],
  }),
  mounted() {
    this.slideWidth = 373 * this.projects.length;
    this.windowWidth = window.innerWidth;
    if (this.windowWidth <= 375)
      this.dist = (this.slideWidth - this.windowWidth) / 2 - 10;
    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth, { passive: true });
      this.getWindowWidth();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 375)
        this.dist = (this.slideWidth - this.windowWidth) / 2 - 10;
      else this.dist = 0;
    },
    onPrev() {
      if (this.windowWidth > this.slideWidth) return;
      if ((this.slideWidth - this.windowWidth) / 2 > Math.abs(this.dist + 373))
        this.dist += 373;
      else this.dist = (this.slideWidth - this.windowWidth) / 2;
    },
    onNext() {
      if (this.windowWidth > this.slideWidth) return;
      if (this.dist === -(this.slideWidth - this.windowWidth) / 2) {
        this.dist = (this.slideWidth - this.windowWidth) / 2;
        return;
      }
      if ((this.slideWidth - this.windowWidth) / 2 > Math.abs(this.dist - 373))
        this.dist -= 373;
      else this.dist = -(this.slideWidth - this.windowWidth) / 2;
    },
  },
};
</script>

<style scoped>
.splide-card {
  border-radius: 10px !important;
  margin: 20px 20px 60px 20px !important;
  box-shadow: 0 17px 75px rgba(0, 0, 0, 0.07),
    0 6.20528px 27.3762px rgba(0, 0, 0, 0.0482987),
    0 3.01255px 13.2907px rgba(0, 0, 0, 0.0389404),
    0 1.47681px 6.51533px rgba(0, 0, 0, 0.0310596),
    0 0.583932px 2.57617px rgba(0, 0, 0, 0.0217013);
}
@media only screen and (max-width: 599px) {
  .splide-card {
    margin-bottom: 40px !important;
  }
  .homecards-section-header {
    margin-top: 44px !important;
  }
  .h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
  }
  .p4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
}
</style>
