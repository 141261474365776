<template>
  <v-row justify="center">
    <v-col cols="auto" class="pt-12 pb-0 pb-sm-3">
      <Heading type="h2 MainDark" class="shadow-text">Easy Setup</Heading>
    </v-col>
    <div style="width: 100%"></div>
    <v-col cols="12" class="pt-2 pt-sm-3 pb-0 pb-sm-3" style="max-width: 724px">
      <Texting type="p4 MainDark" style="text-align: center; opacity: 0.8"
        >Manage all your rewards with a single dashboard</Texting
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-col
      cols="12"
      class="pt-0 pt-sm-3"
      style="
        max-width: 1200px;
        position: relative;
        overflow: auto;
        white-space: nowrap;
      "
    >
      <div style="min-width: 800px">
        <!-- <Images style="z-index: 2;position: relative" width="100%" name="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703039/images/perks_table_lrztpz.jpg" /> -->
        <images-lazy
          background-color="#FFFFFF"
          width="100%"
          lazy-src="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703039/images/perks_table_lrztpz.jpg"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Heading from "@/components/Heading";
//import Images from "@/components/Images/Images";
import Texting from "@/components/Texting";
import ImagesLazy from "../../components/ImagesLazy.vue";
export default {
  components: { Texting, Heading, ImagesLazy },
  data: () => ({}),
};
</script>

<style scoped>
@media only screen and (max-width: 599px) {
  .h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
  }
  .p4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
}
</style>
