<template>
  <v-row
    class="customers-say"
    justify="center"
    style="position: relative; z-index: 1"
  >
    <v-col
      cols="auto"
      class="text-center customer-say-section-header"
      style="
        padding-top: 90px;
        padding-bottom: 44px;
        padding-right: 24px;
        padding-left: 24px;
      "
    >
      <Heading type="h2 mywhite" class="shadow-text"
        >Here is what our customers say <br class="d-none d-sm-flex" />
        about Perkfection</Heading
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-col cols="12" style="max-width: 784px" class="d-none d-sm-block">
      <ul class="list">
        <li
          class="list-item"
          :class="{
            order1: item.order === 1,
            order2: item.order === 2,
            order3: item.order === 3,
            'card--out': item.out,
          }"
          v-for="(item, i) in items"
          :key="i"
        >
          <Texting type="p6 MainDark" style="opacity: 0.8">{{
            item.title
          }}</Texting>
          <div
            class="d-flex align-center"
            style="position: absolute; bottom: 54px"
          >
            <Images height="56" style="border-radius: 50%" :name="item.image" />
            <div class="shadow-text ml-6">
              <Texting type="p4-1-2 MainDark">{{ item.fullname }}</Texting>
              <Texting type="p2 MainDark">{{ item.description }}</Texting>
            </div>
          </div>
        </li>
      </ul>
    </v-col>

    <!--    <swiper class="swiper d-block d-sm-none" :options="swiperOption">
      <swiper-slide
        v-for="(item, i) in items"
        :key="i"
        class="justify-center d-flex"
        style="border-radius: 15px"
      >
        <div class="list-item-mobile mx-8">
          <Texting
            type="p3small-2 MainDark"
            class="d-flex"
            style="opacity: 0.8"
            >{{ item.title }}</Texting
          >
          <div
            class="d-flex align-center"
            style="position: absolute; bottom: 40px"
          >
            <Images height="56" style="border-radius: 50%" :name="item.image" />
            <div class="shadow-text ml-6">
              <Texting type="p4-1-2 MainDark">{{ item.fullname }}</Texting>
              <Texting type="p2 MainDark" style="padding-right: 44px">{{
                item.description
              }}</Texting>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div
        class="swiper-button-prev"
        @click="handlePrev"
        slot="button-prev"
      ></div>
      <div
        class="swiper-button-next"
        @click="handleNext"
        slot="button-next"
      ></div>
    </swiper>-->

    <splide :options="myOptions" :slides="items" class="d-sm-none d-block">
      <template v-slot:controls>
        <div
          class="splide__arrows d-flex justify-center"
          style="padding-top: 60px; padding-bottom: 36px"
        >
          <v-col cols="2" class="d-none d-md-flex"></v-col>
          <div class="splide__arrow--prev pr-4">
            <Images
              id="customer-say-mobile-prev-icon-546cfed2"
              name="prev_icon.png"
            />
          </div>
          <div class="splide__arrow--next">
            <Images
              id="customer-say-mobile-next-icon-50cce6e8"
              name="next_icon.png"
            />
          </div>
        </div>
      </template>
      <splide-slide
        v-for="(item, i) in items"
        :key="i"
        class="justify-center d-flex"
        style="margin-left: 130px; border-radius: 15px"
      >
        <div class="list-item-mobile">
          <Texting
            type="p3small-2 MainDark"
            class="d-flex"
            style="opacity: 0.8"
            >{{ item.title }}</Texting
          >
          <div
            class="d-flex align-center"
            style="position: absolute; bottom: 40px"
          >
            <Images height="56" style="border-radius: 50%" :name="item.image" />
            <div class="shadow-text ml-6">
              <Texting type="p4-1-2 MainDark">{{ item.fullname }}</Texting>
              <Texting type="p2 MainDark" style="padding-right: 44px">{{
                item.description
              }}</Texting>
            </div>
          </div>
        </div>
      </splide-slide>
    </splide>
    <div style="width: 100%"></div>
    <v-col
      cols="auto"
      style="padding-bottom: 53px; padding-top: 54px"
      class="d-none d-sm-flex"
    >
      <Images
        id="customer-say-prev-icon-70f3ca9a"
        @click.native="onPrev"
        class="pointer"
        name="prev_icon.png"
        style="padding-right: 16px"
      />
      <Images
        id="customer-say-next-icon-74dafc46"
        @click.native="onNext"
        class="pointer"
        name="next_icon.png"
      />
    </v-col>
  </v-row>
</template>

<script>
import Heading from "@/components/Heading";
import Images from "@/components/MyImages";
import Texting from "@/components/Texting";
import { Swiper } from "swiper";

export default {
  components: { Texting, Images, Heading },

  data: () => ({
    swiperOption: {
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    swiper: null,
    myOptions: {
      type: "loop",
      lazyLoad: "nearby",
      perMove: 1,
      perPage: 1,
      pagination: false,
      speed: 200,
      width: "100%",
      arrows: "slider",
    },
    showitem: 1,
    items: [
      {
        title:
          "Perkfection is just the right tool that automatically adjusts our reward availability based on popularity and inventory,  allowing us to maintain a high conversion rate throughout without the hassle of manual editing.",
        fullname: "Boz Zou",
        image: "customer1.png",
        description: "CEO UM Systems, UV Mask Campaign, Raised $3 mln",
        order: 1,
        out: false,
      },
      {
        title:
          "Perkfection really helped us create the “limited units left” feel throughout the campaign by monitoring and topping the rewards up. Absolutely brilliant!",
        fullname: "Hannan Hakim",
        image: "customer2.jpg",
        description: "Hyper Lychee - Skadu Co-founder & CEO",
        order: 2,
        out: false,
      },
      {
        title:
          "Once, our most popular reward sold out overnight and by the time we turned it back on, we'd lost $10k. With Perkfection we sleep much better.",
        fullname: "Arun Raj",
        image: "customer3.jpg",
        description: "CEO of Altifarm Pico Campaign, Raised $1.7 mln",
        order: 3,
        out: false,
      },
    ],
  }),
  mounted() {
    this.swiper = new Swiper(".swiper", {
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },
  methods: {
    handlePrev() {
      this.swiper.slidePrev();
    },
    handleNext() {
      this.swiper.slideNext();
    },
    onPrev() {
      if (this.items[1].order === 1) {
        this.items[1].out = true;
        this.items[0].out = false;
        this.items[2].out = false;
        this.items[1].order = 3;
        this.items[2].order = 2;
        this.items[0].order = 1;
        return;
      }
      if (this.items[2].order === 1) {
        this.items[2].out = true;
        this.items[0].out = false;
        this.items[1].out = false;
        this.items[2].order = 3;
        this.items[0].order = 2;
        this.items[1].order = 1;
        return;
      }
      if (this.items[0].order === 1) {
        this.items[2].out = false;
        this.items[0].out = true;
        this.items[1].out = false;
        this.items[0].order = 3;
        this.items[1].order = 2;
        this.items[2].order = 1;
      }
    },
    onNext() {
      if (this.items[1].order === 1) {
        this.items[1].out = true;
        this.items[0].out = false;
        this.items[2].out = false;
        this.items[1].order = 3;
        this.items[0].order = 2;
        this.items[2].order = 1;
        return;
      }
      if (this.items[2].order === 1) {
        this.items[1].out = false;
        this.items[0].out = false;
        this.items[2].out = true;
        this.items[2].order = 3;
        this.items[1].order = 2;
        this.items[0].order = 1;
        return;
      }
      if (this.items[0].order === 1) {
        this.items[0].out = true;
        this.items[1].out = false;
        this.items[2].out = false;
        this.items[0].order = 3;
        this.items[2].order = 2;
        this.items[1].order = 1;
      }
    },
  },
};
</script>

<style scoped>
.customers-say {
  background: linear-gradient(
      7.46deg,
      #27e1af -133.29%,
      #9b60e7 25.27%,
      #8015c2 131.89%
    ),
    #f8f7fc;
}
.list {
  position: relative;
  list-style: none;
  height: 432px;
  padding: 0;
  margin: 0 16px 0 16px;
}

@media only screen and (max-width: 636px) {
  .list-item {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
@media only screen and (max-width: 484px) {
  .list {
    height: 520px;
  }
}
@media only screen and (max-width: 388px) {
  .list {
    height: 550px;
  }
}
.list-item {
  padding: 65px 65px 54px 65px;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0 17px 75px rgba(0, 0, 0, 0.07),
    0 6.20528px 27.3762px rgba(0, 0, 0, 0.0482987),
    0 3.01255px 13.2907px rgba(0, 0, 0, 0.0389404),
    0px 1.47681px 6.51533px rgba(0, 0, 0, 0.0310596),
    0px 0.583932px 2.57617px rgba(0, 0, 0, 0.0217013);
  border-radius: 15px;
  transition: transform 0.6s cubic-bezier(0.8, 0.2, 0.1, 0.8) 0.1s,
    background 0.4s linear;
  -webkit-transition: transform 0.6s cubic-bezier(0.8, 0.2, 0.1, 0.8) 0.1s,
    background 0.4s linear;
}
.list-item-mobile {
  position: relative;
  padding: 53px 32px 54px 32px;
  display: block;
  width: 343px;
  height: 420px;
  background: #ffffff;
  box-shadow: 0 17px 75px rgba(0, 0, 0, 0.07),
    0 6.20528px 27.3762px rgba(0, 0, 0, 0.0482987),
    0 3.01255px 13.2907px rgba(0, 0, 0, 0.0389404),
    0px 1.47681px 6.51533px rgba(0, 0, 0, 0.0310596),
    0px 0.583932px 2.57617px rgba(0, 0, 0, 0.0217013);
  border-radius: 15px;
}

.card--out {
  animation: card-out 0.6s cubic-bezier(0.8, 0.2, 0.1, 0.8);
  -webkit-animation-name: card-out;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-timing-function: cubic-bezier(0.8, 0.2, 0.1, 0.8);
}
@keyframes card-out {
  0% {
    z-index: 20;
  }
  50% {
    transform: translateY(-120%);
  }
  80% {
    z-index: 1;
  }
  100% {
    transform: translate3d(0px, 16px, 0px) scale(0.88, 0.98);
    opacity: 0.6;
  }
}
@-webkit-keyframes card-out {
  0% {
    z-index: 20;
  }
  50% {
    -webkit-transform: translateY(-120%);
  }
  80% {
    z-index: 1;
  }
  100% {
    -webkit-transform: translate3d(0px, 16px, 0px) scale(0.88, 0.98);
    opacity: 0.6;
  }
}

.order1 {
  z-index: 4;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale(1, 1);
  -webkit-transform: translate3d(0px, 0px, 0px) scale(1, 1);
}
.order2 {
  z-index: 3;
  opacity: 0.8;
  transform: translate3d(0px, 8px, 0px) scale(0.94, 0.99);
  -webkit-transform: translate3d(0px, 8px, 0px) scale(0.94, 0.99);
}
.order3 {
  z-index: 2;
  opacity: 0.6;
  transform: translate3d(0px, 16px, 0px) scale(0.88, 0.98);
  -webkit-transform: translate3d(0px, 16px, 0px) scale(0.88, 0.98);
}
@media only screen and (max-width: 599px) {
  .h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
  }
  .customer-say-section-header {
    padding-top: 31px !important;
    padding-bottom: 29px !important;
  }
  .splide__arrows {
    padding-top: 32px !important;
  }

  .swiper-button-prev {
    background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1617703041/images/prev_icon_customer_yrsbl8.png") !important;
    width: 56px;
    height: 56px;
    color: transparent;
    bottom: 44px !important;
    left: calc(50% - 72px) !important;
    top: auto !important;
    z-index: 100 !important;
  }
  .swiper-button-next {
    background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1617703036/images/next_icon_customer_accaei.png") !important;
    width: 56px;
    height: 56px;
    color: transparent;
    bottom: 44px !important;
    left: calc(50% + 8px) !important;
    top: auto !important;
    z-index: 100 !important;
  }
  .swiper {
    padding-bottom: 152px;
  }
}
</style>
