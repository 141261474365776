<template>
  <v-container class="cont" id="startpage_container" fluid>
    <v-row
      justify-md="space-between"
      justify="center"
      align="center"
      style="height: 100%"
    >
      <v-col md="5" xs="12" style="max-width: 500px; padding-top: 31px">
        <Heading
          type="h2 MainDark"
          class="mb-7 text-center text-sm-left"
          style="z-index: 1000"
        >
          Increase <span class="grey10">Conversions</span> Without Any Effort
        </Heading>
        <v-row class="d-flex d-sm-none justify-center">
          <v-col cols="10" class="pb-8">
            <Images
              width="100%"
              alt="reward automation robot"
              name="https://res.cloudinary.com/dyf7aejih/image/upload/v1620130160/images/home_robot_mobile_ayc5dw.jpg"
            />
            <!-- <images-lazy width="100%" alt="reward automation robot" lazy-src="https://res.cloudinary.com/dyf7aejih/image/upload/v1620130160/images/home_robot_mobile_ayc5dw.jpg"/> -->
          </v-col>
        </v-row>
        <Texting
          type="p4-1n MainDark"
          class="mb-sm-12 mb-5"
          style="opacity: 0.8"
          >Perkfection Automates Your Kickstarter or Indiegogo Rewards</Texting
        >
        <Button
          id="home-signup-a294fba6"
          size="signup"
          :handleClick="signUp"
          texting="p3bold mywhite"
          type="primary"
          class="sign-up-button d-none d-sm-flex"
          >get started</Button
        >
        <Images class="d-none d-sm-flex" name="platforms.svg" />
        <div
          style="position: relative; top: -135px; left: 417px; z-index: 101"
          class="d-none d-md-flex"
        >
          <Images
            name="https://res.cloudinary.com/dyf7aejih/image/upload/v1637668211/images/signup_arrow_zilfri.svg"
          />
        </div>
        <Button
          id="home-mobile-signup-bba98aee"
          :handleClick="signUp"
          size="larger"
          texting="p3bold mywhite"
          type="primary"
          class="sign-up-button d-flex d-sm-none py-5"
          style="
            margin-bottom: 35px;
            padding-right: 110px !important;
            padding-left: 110px !important;
          "
          >get started</Button
        >
        <v-row justify="center" class="d-flex d-sm-none">
          <v-col cols="auto" style="padding-top: 23px; padding-bottom: 10px">
            <Images name="kickstarter_home.png" />
          </v-col>
          <div style="width: 100%"></div>
          <v-col cols="auto">
            <Images name="Indiegogo_home.png" />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="d-none d-sm-flex"
        md="6"
        xs="10"
        style="padding-left: 0; margin-right: 14px; z-index: 100"
      >
        <Images
          name="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703045/images/robot_gift_mtztz7.png"
          alt="reward automation robot"
          width="100%"
          class="start-image"
        />
        <!-- <images-lazy width="100%" background-color="#FFFFFF" lazy-src="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703045/images/robot_gift_mtztz7.png"/> -->
        <!-- <link rel="preload" as="image" href="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703045/images/robot_gift_mtztz7.png" imagesizes="50vw" /> -->
      </v-col>
    </v-row>
    <div class="right-circle d-none d-sm-flex">
      <Images name="right-sector.svg" width="100%" height="100%" />
    </div>
    <div class="left-circle d-none d-sm-flex">
      <Images name="left-sector.png" />
    </div>
  </v-container>
</template>

<script>
import Texting from "../../components/Texting";
import Heading from "../../components/Heading";
import Images from "../../components/MyImages";
import Button from "../../components/Button";
import myServices from "@/services/resource";
//import ImagesLazy from '../ImagesLazy/ImagesLazy.vue';
export default {
  components: {
    Button,
    Images,
    Heading,
    Texting,
  },
  data: () => ({}),
  methods: {
    signUp() {
      this.$emit("opensignup");
      myServices.myAnalyticsEvents(
        this,
        "Homepage",
        '"Get started" button click',
      );
    },
  },
};
</script>

<style scoped>
.cont {
  margin-top: 123px;
  max-width: 1080px;
}

.sign-up-button {
  white-space: nowrap;
  box-shadow: 0px 1.46302px 3.51372px rgba(158, 51, 224, 0.198454),
    0px 4.91399px 9.32645px rgba(158, 51, 224, 0.267034),
    0px 22px 31px rgba(158, 51, 224, 0.34);
}

.right-circle {
  position: absolute;
  width: 31%;
  max-width: 460px;
  min-width: 184px;
  top: 0;
  right: 0;
}

@media only screen and (min-width: 1650px) {
  .right-circle {
    max-width: none;
    width: 35%;
    max-height: 600px;
  }
}
@media only screen and (min-width: 922px) {
  .cont {
    height: 100% !important;
  }
}

@media only screen and (max-width: 499px) {
  .right-circle {
    width: 50%;
  }
}

.left-circle {
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 599px) {
  .sign-up-button {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .h2 {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
  }

  .p4-1n {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }

  .start-image {
    margin-top: 0 !important;
    max-width: 402px;
  }

  .cont {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
  }
}

@media only screen and (min-height: 843px) and (min-width: 1108px) {
  .cont {
    height: 93vh;
    margin-top: 0;
    margin-bottom: 0;
    align-items: center;
  }
}

@media only screen and (max-width: 922px) {
  .start-image {
    width: 100%;
    max-width: 509px;
    min-width: 300px;
  }
}

@media only screen and (max-width: 726px) {
  .start-image {
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    display: block;
  }
}
</style>
