<template>
  <v-row justify="center" class="free-teial-row" style="max-height: 436px">
    <v-col
      cols="12"
      class="free-trial py-0"
      :style="{
        backgroundSize: windowWidth + 'px',
        height: windowHeight + 'px',
      }"
    >
      <v-row justify="center" align="center" style="height: 100%">
        <v-col cols="auto" class="d-block">
          <v-row justify="center">
            <v-col cols="auto" class="pb-0 pb-sm-1">
              <Heading type="h2 mywhite" style="text-align: center"
                >3 Days 100% Free</Heading
              >
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto" class="pt-0 pt-sm-3">
              <Texting type="p4 mywhite" style="text-align: center"
                >Try for free and see for yourself! No credit card
                required</Texting
              >
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto" style="padding-top: 22px">
              <Button
                id="freeTrialPage-get-started-button-40c7a526"
                :handleClick="signUp"
                class="d-none d-sm-flex get-started-button"
                type="white"
                size="normal"
                texting="p2bold grey10"
                >Get Started</Button
              >
              <Button
                id="freeTrialPage-mobile-get-started-button-589d284c"
                :handleClick="signUp"
                class="d-flex d-sm-none get-started-button"
                style="
                  padding-top: 18px !important;
                  padding-bottom: 18px !important;
                "
                type="white"
                size="larger"
                texting="p2bold grey10"
                >Get Started</Button
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import Texting from "./Texting";
import Heading from "./Heading";
import Button from "./Button";
import myServices from "@/services/resource";
export default {
  components: { Button, Heading, Texting },
  data: () => ({
    windowWidth: null,
    windowHeight: null,
  }),
  mounted() {
    if (window.innerWidth + 25 > 1440) {
      this.windowWidth = window.innerWidth + 25;
      this.windowHeight = window.innerWidth / 3.3;
    }
    window.onresize = () => {
      if (window.innerWidth + 25 > 1440) {
        this.windowWidth = window.innerWidth + 25;
        this.windowHeight = window.innerWidth / 3.3;
      }
    };
  },
  methods: {
    signUp() {
      this.$emit("open-signup");
      if (this.$route.name === "HowItWorks") {
        myServices.myAnalyticsEvents(this, "How it works", "CTA button click");
        return;
      }

      myServices.myAnalyticsEvents(
        this,
        "Homepage",
        '2nd "Get Started" button click',
      );
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 599px) {
  .h2 {
    line-height: 40px;
  }
  .p4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
  }
  .free-teial-row {
    max-height: 586px !important;
  }
}
.get-started-button {
  box-shadow: 0px 18px 34px rgba(36, 18, 77, 0.24),
    0px 2px 9px rgba(36, 18, 77, 0.1), 0px 0px 3px rgba(36, 18, 77, 0.16);
}
</style>
