<template>
  <v-row justify="center" id="home_video_section">
    <v-col cols="auto">
      <Heading
        id="video-section-title"
        type="h2 MainDark"
        class="shadow-text text-center pt-4 px-6"
        style="max-width: 786px"
        >Get higher conversion rates by automating your rewards!</Heading
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-col cols="11" sm="7" class="home-video py-0">
      <v-row
        @mouseover="hoverActive = true"
        @mouseleave="hoverActive = false"
        class="pointer"
      >
        <v-col
          cols="12"
          class="pa-0"
          style="position: relative; z-index: 10; overflow: hidden"
        >
          <div v-if="!showvid" @click="playVideo" id="videocover">&nbsp;</div>
          <div class="outer">
            <video
              preload="auto"
              poster="https://res.cloudinary.com/dyf7aejih/image/upload/v1637673368/images/image_65_rxzi3s.png"
              id="home_video"
              width="100%"
              playsinline
              :controls="showvid ? true : false"
            >
              <source
                src="https://res.cloudinary.com/dyf7aejih/video/upload/v1637671953/videos/Perkfection_Final_h4lsdx.webm"
                type="video/webm"
              />
              <source
                src="https://res.cloudinary.com/dyf7aejih/video/upload/v1637671889/videos/Perkfection_Final_lkzspi.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>

          <!--          <Images
            v-show="!showvid"
            name="https://res.cloudinary.com/dyf7aejih/image/upload/v1637673368/images/image_65_rxzi3s.png"
            width="100%"
            height="100%"
          />-->
        </v-col>
      </v-row>
      <div
        v-if="!showvid"
        @mouseover="hoverActive = true"
        @mouseleave="hoverActive = false"
        id="home-video-play-icon-2f162db8"
        :class="{ 'play-icon-hovered': hoverActive, 'play-icon': !hoverActive }"
        @click="playVideo"
      ></div>
      <Images
        name="hiw_back1.png"
        class="d-none d-md-flex"
        style="position: absolute; bottom: 29px; left: -95px"
      />
      <Images
        name="hiw_back2.png"
        class="d-none d-md-flex"
        style="position: absolute; bottom: -95px; left: -75px"
      />
      <Images
        name="hiw_back3.png"
        class="d-none d-md-flex"
        style="position: absolute; top: -11px; right: 110px"
      />
      <Images
        name="hiw_back4.png"
        class="d-none d-md-flex"
        style="position: absolute; top: -55px; right: -105px; z-index: 2"
      />
    </v-col>
  </v-row>
</template>

<script>
import Heading from "@/components/Heading";
import Images from "@/components/MyImages";
import myServices from "@/services/resource";

export default {
  components: { Images, Heading },
  data: () => ({
    showvid: false,
    pageOfsset: null,
    hoverActive: false,
  }),
  created() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    playVideo() {
      myServices.myAnalyticsEvents(this, "Homepage", "Video play click");
      let vid = document.getElementById("home_video");
      this.showvid = true;
      this.pageOfsset = window.pageYOffset;
      vid.play();
    },
    handleScroll() {
      if (Math.abs(window.pageYOffset - this.pageOfsset) > 500) {
        let vid = document.getElementById("home_video");
        this.showvid = false;
        vid.pause();
      }
    },
  },
};
</script>

<style scoped>
.outer {
  width: 100%;
  height: auto;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  max-height: 96%;
}
.home-video {
  max-width: 785px;
  max-height: 449px;
  margin-bottom: 207px;
  margin-top: 60px;
  position: relative;
  z-index: 5;
}
#home-video-play-icon-2f162db8 {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  cursor: pointer;
}

.play-icon {
  background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1638168250/images/play_icon2_hovered_ncjdyf.svg");
  background-position: center;
  background-size: contain;
}
.play-icon-hovered {
  background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1638168250/images/play_icon2_opg7mm.svg");
  width: 102px !important;
  height: 102px !important;
}
@media only screen and (max-width: 599px) {
  .h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
  }
  .p4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
  }
  .home-video {
    margin-top: 12px;
    margin-bottom: 64px;
  }
  .outer {
    border-radius: 11px;
  }
  #home_video {
    /*border-radius: 11px !important;*/

    position: relative;
    left: 0;
    top: 0;
    opacity: 1;
  }
  #home-video-play-icon-2f162db8 {
    top: 49% !important;
    max-width: 58px !important;
    max-height: 58px !important;
  }
  #home-video-play-icon-2f162db8.play-icon-hovered {
    max-width: 70px !important;
    max-height: 70px !important;
  }
  .play-icon-hovered {
    background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1638168250/images/play_icon2_opg7mm.svg");
    background-position: center;
    background-size: contain;
  }
}
#videocover {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
